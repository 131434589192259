<template>
    <div>
        <v-simple-table>
            <thead>
                <tr>
                    <th>Stream Type</th>
                    <th>Codec Name</th>
                    <th>Description</th>
                    <th>Codec Tag</th>
                    <th>Compatible</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(vs, index) in metadata.details.video_streams" :key="`vs-${index}`" class="text-left">
                    <td class="text-uppercase">{{ vs.codec_type }}</td>
                    <td class="text-uppercase">{{ vs.codec_name }}</td>
                    <td>{{ vs.codec_long_name }}</td>
                    <td>{{ vs.codec_tag }}</td>
                    <td>{{ vs.is_compatible ? 'Yes' : 'No' }}</td>
                </tr>
                <tr v-for="(as, index) in metadata.details.audio_streams" :key="`as-${index}`" class="text-left">
                    <td class="text-uppercase">{{ as.codec_type }}</td>
                    <td class="text-uppercase">{{ as.codec_name }}</td>
                    <td>{{ as.codec_long_name }}</td>
                    <td>{{ as.codec_tag }}</td>
                    <td>{{ as.is_compatible ? 'Yes' : 'No' }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>

export default {
    name: "WillItPlayMetadata",
    props: {
        metadata: {
            type: Object,
            required: true
        }
    }
}
</script>
